import { type FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Sidebar } from 'flowbite-react';
import {
  HiOutlineBriefcase,
  HiOutlineChartPie,
  HiOutlineCheckCircle,
  HiOutlineCog,
  HiOutlineLocationMarker,
} from 'react-icons/hi';

const GsSidebar: FC = () => {
  const location = useLocation();
  const params = useParams();
  const isAnalyticsDetail =
    location.pathname.startsWith('/analytics/') &&
    location.pathname.split('/').length > 2;
  const dashboardPath =
    params.analyticId == null ? '' : `/analytics/${params.analyticId}`;
  const keyEventsPath =
    params.analyticId == null
      ? ''
      : `/analytics/${params.analyticId}/settings/key_events`;
  const searchConsolePath =
    params.analyticId == null
      ? ''
      : `/analytics/${params.analyticId}/settings/search_console`;
  const planPath =
    params.analyticId == null
      ? ''
      : `/analytics/${params.analyticId}/settings/plan`;

  return (
    <Sidebar
      aria-label="Sidebar with multi-level dropdown"
      className="sidebar-area w-1/6"
    >
      <Sidebar.Items>
        {isAnalyticsDetail && (
          <Sidebar.ItemGroup>
            <Sidebar.Item to={dashboardPath} as={Link} icon={HiOutlineChartPie}>
              ダッシュボード
            </Sidebar.Item>
            <Sidebar.Collapse icon={HiOutlineCog} label="設定">
              <Sidebar.Item
                to={keyEventsPath}
                as={Link}
                icon={HiOutlineLocationMarker}
                className="truncate mt-4"
              >
                キーイベント
              </Sidebar.Item>
              <Sidebar.Item
                to={searchConsolePath}
                as={Link}
                icon={HiOutlineBriefcase}
                className="truncate mt-4"
              >
                SearchConsole
              </Sidebar.Item>
              <Sidebar.Item
                to={planPath}
                as={Link}
                icon={HiOutlineCheckCircle}
                className="truncate mt-4"
              >
                ご利用プラン
              </Sidebar.Item>
            </Sidebar.Collapse>
          </Sidebar.ItemGroup>
        )}
      </Sidebar.Items>
    </Sidebar>
  );
};

export default GsSidebar;
